@import "_mobile-ui";

.game-container {
  position: absolute;
  width: 100vh;
  height: 100vh;
  max-width: $max-ratio;
  max-height: $max-ratio;
  right: 0;
  top: 0;
  pointer-events: none;
  z-index: 10;
  > .game {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
  }
  @include mobile {
    width: 100vw;
    height: 100vw;
    max-width: 100vw;
    max-height: 100vw;
  }
}

.Snake {
  outline: none;
}

.snake-segment {
  $unit: 6.25%;
  position: absolute;
  background-size: 100% 100%;
  background-position: center center;
  width: $unit;
  height: $unit;
  overflow: visible;

  &.end.right,
  &.head.left {
    background-image: url("../images/snake/end-left.png");
  }
  &.end.left,
  &.head.right {
    background-image: url("../images/snake/end-right.png");
  }
  &.end.down,
  &.head.up {
    background-image: url("../images/snake/end-up.png");
  }
  &.end.up,
  &.head.down {
    background-image: url("../images/snake/end-down.png");
  }

  &.body.up,
  &.body.down {
    background-image: url("../images/snake/vertical.png");
  }
  &.body.left,
  &.body.right {
    background-image: url("../images/snake/horizontal.png");
  }

  &.corner.up-right {
    background-image: url("../images/snake/up-right.png");
  }
  &.corner.up-left,
  &.corner.right-down {
    background-image: url("../images/snake/right-down.png");
  }
  &.corner.down-right {
    background-image: url("../images/snake/down-right.png");
  }
  &.corner.up-right {
    background-image: url("../images/snake/up-right.png");
  }
  &.corner.left-down {
    background-image: url("../images/snake/up-right.png");
  }
  &.corner.left-up {
    background-image: url("../images/snake/down-right.png");
  }
  &.corner.down-left,
  &.corner.right-up {
    background-image: url("../images/snake/left-up.png");
  }

  // &.corner.type-1 {
  //   background-color: red;
  // }
  // &.corner.type-2 {
  //   background-color: blue;
  // }
  // &.corner.type-3 {
  //   background-color: green;
  // }
  // &.corner.type-4 {
  //   background-color: yellow;
  // }
}

.Snake.points-0 .snake-segment {
  background-image: url("../images/snake/bubba.png");
}

.pill {
  position: absolute;
  width: 6.25%;
  height: 6.25%;
  overflow: visible;
  background-image: url("../images/snake/food.png");
  background-size: contain;
  background-position: center center;
}

.Snake.is-ded {
  .snake-segment {
  }
  .pill {
    opacity: 0;
  }
}
