@-ms-viewport {
  width: device-width;
}

@font-face {
  font-family: "Untitled Serif EC";
  src: url("../fonts/UntitledSerifWeb-Regular.eot?") format("eot"),
    url("../fonts/UntitledSerifWeb-Regular.woff2") format("woff2"),
    url("../fonts/UntitledSerifWeb-Regular.woff") format("woff");
}

@import "_vars";
@import "mixins/_transition";
@import "mixins/_breakpoints";

html {
  box-sizing: border-box;
  font-family: "Untitled Serif EC", Serif;
  font-size: 20px;
  line-height: 25px;
}

@import "snake/_index";
@import "ui/_preloader";
@import "ui/_instagram";
@import "ui/_nav";
@import "snake/_game-ui";

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Untitled Serif EC", Serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// .header {
//   grid-area: header;
//   align-self: center;
// }
//
// .controls {
//   position: absolute;
//   right: 0;
//   left: 100vh;
//   bottom: 6.25vh;
//   text-align: center;
//   grid-area: controls;
//   justify-self: center;
//   padding: 20px;
//   color: $brown;
// }
