.Nav {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: calc(100% - 100vh);
  color: $brown;
  z-index: 100;
  font-size: 16px;
  line-height: 22px;
  @include mobile {
    bottom: 3.125vh;
    top: 100vw;
    width: 100%;
  }
  @include medium {
    width: 35vw;
  }
  > .info {
    display: flex;
    flex-wrap: wrap;
    padding: 6.25vh 6.25vh 0;
    @include medium {
      padding: 3.125vh 3.125vh 0;
    }

    @include mobile {
      padding: 3.125vh 3.125vh 0;
    }

    > .column {
      width: 50%;
      min-width: 200px;
      @include medium {
        width: 100%;
        min-width: auto;
      }
      @include mobile {
        min-width: auto;
      }
    }
  }
  h1,
  h2,
  p,
  ul {
    font-weight: normal;
    font-size: inherit;
    padding: 0;
    margin: 0;
  }
  h1 {
    font-size: 22px;
    line-height: 21px;
    padding-bottom: 5.25vh;
    > svg {
      margin-right: 20px;
      float: left;
    }
    > span {
      display: block;
    }
    @include mobile {
    }
  }
  ul {
    margin: 3.125vh 0;
    li {
      list-style-type: none;
    }
    @include mobile {
      margin: 1em 0 0;
    }
  }
  a,
  button {
    color: $brown;
    text-decoration: none;
    border: 0;
    background-color: transparent;
    padding: 0;
    margin: 0;
    font-size: inherit;
    font-family: inherit;
    position: relative;
    &::after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -1px;
      background-color: rgba($brown, 0.5);
      height: 1px;
      content: "";
    }
    &:hover {
      color: $dark-brown;
      &::after {
        opacity: 0;
      }
    }
  }
}

.Nav .Caption {
  position: absolute;
  left: 6.25vh;
  bottom: 6.25vh;
  max-width: 70%;
  blockquote {
    margin: 0;
    padding: 0;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 3.125vh;
  }
  @include medium {
    left: 3.125vh;
    bottom: 3.125vh;
  }
  @include mobile {
    display: none;
  }
}

.Nav .mobile {
  display: none;
  @include mobile {
    display: block;
  }
}

.Nav.is-playing {
  @include mobile {
    .info {
      display: none;
    }
  }
}
