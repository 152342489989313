.IG {
  position: absolute;
  z-index: 5;
  right: 0;
  top: 0;
  width: 100vh;
  height: 100vh;

  max-width: $max-ratio;
  max-height: $max-ratio;
  // pointer-events: none;
  display: block;
  background-color: $brown;
  background-image: url("../images/grid.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;

  > .internal {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  @include mobile {
    width: 100vw;
    height: 100vw;
    max-width: 100vw;
    max-height: 100vw;
  }
}

.IG .Post {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  > .frame {
    position: absolute;
    overflow: hidden;
    img {
      display: block;
      width: 100%;
      height: 100%;
      opacity: 0;
      @include transition(opacity 0.4s ease-out);
      &.is-loaded {
        opacity: 1;
      }
    }
  }
}

.IG .Post.is-hovered {
  background-color: $brown;
}

.IG .Post .Slideshow {
  img {
    position: absolute;
    display: block;
    width: 100%;
    opacity: 0;
    @include transition(opacity 0.2s linear 0.2s);
    &.is-active {
      opacity: 1;
      @include transition(opacity 0.2s linear 0s);
    }
  }
}

.IG.is-ded .Post {
  > .frame {
    background-color: $dark-brown;
    img {
      opacity: 0 !important;
    }
  }
}
