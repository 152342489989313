.GameUI {
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0 6.25vh;
  display: flex;
  flex-wrap: nowrap;
  @include medium {
    padding: 3.125vh;
  }
  > div {
    width: 50%;
    @include medium {
      width: 100%;
    }
  }
  @include mobile {
    padding: 0 3.125vh;
    .desktop {
      display: none;
    }
  }
}

.GameUI {
  @include mobile {
    padding-top: 3.125vh;
  }
}

.Nav.is-playing {
  pointer-events: none;
}
