.Preloader {
  position: fixed;
  z-index: 999;
  left: 0;
  width: 100vw;
  height: 100vh;
  top: 0;
  display: none;
  background-color: $brown;
}

.Preloader .logo {
  max-width: 952px;
  margin: 0 auto;
  margin-top: 200px;
  svg {
    width: 100%;
    display: block;
  }
}

.Preloader svg .box {
  stroke: white;
  stroke-width: 10;
  stroke-miterlimit: 10;
  stroke-linecap: square;
  stroke-linejoin: bevel;
}

.Preloader svg .square {
  fill: white;
}

.Preloader svg .d {
  opacity: 0.3;
}
