@mixin mobile {
  @media (min-width: 0px) and (max-width: 800px) {
    @content;
  }
}
@mixin medium {
  @media (min-width: 800px) and (max-width: 1200px) {
    @content;
  }
}
