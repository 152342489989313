.MobileUI {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 40px;
  text-align: center;
  $size: 15vw;
  z-index: 100;
  display: none;
  @include mobile {
    display: block;
  }
  > button {
    width: $size;
    height: $size;
    background-color: white;
    padding: 0;
    margin: 0;
    border: 0;
    line-height: $size;
    outline: none;
    display: inline-block;
    position: relative;
    color: $brown;
    font-size: 30px;
    &::after {
      position: absolute;
      left: -1px;
      right: -1px;
      top: -1px;
      bottom: -1px;
      border: 2px solid $brown;
      content: "";
    }
  }
  > span {
    vertical-align: top;
    display: inline-block;
    width: $size;
    height: $size;
    box-sizing: border-box;
  }
}
